import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const Sm1 = ({ visible }) => (
  <div id="navbar" className={visible ? 'slideIn' : 'slideOut'}>

    <a
      rel="noopener noreferrer"
      href="https://jujharpannu.com/index.php"
      taget="_blank"
    >
      <img className="img-icon" src="images/art.jpg" alt="Art" />
      <br />
      <br />
      {/*
      <a
        href="http://rebabre.com/blog"
        rel="noopener noreferrer"
        taget="_blank"
      >
Blog
      </a>
*/}
    </a>
    <br />
    <br />
  </div>
);

const Sm2 = ({ v2 }) => (
  <div id="sm-id-2" className={v2 ? 'headers-sm slideIn' : 'headers-sm slideOut'}>
    <div>

      <a
        rel="noopener noreferrer"
        href="https://rebabre.com/app/raaga/"
        target="_blank"
      >
Raaga
      </a>
      <br />
      <a
        rel="noopener noreferrer"
        href="https://rebabre.com/bali-punjabi/info/"
        target="_blank"
      >

ਭਲੀ ਪੰ.
      </a>
      <br />

      <a
        rel="noopener noreferrer"
        href="https://rebabre.com/co-ex/"
        target="_blank"
      >
Co-Ex
      </a>
      <br />

      <a
        className="no-style-lnk"
        rel="noopener noreferrer"
        href="https://rebabre.com/apps.html#apps"
        target="_blank"
      >
Other apps
      </a>
    </div>
    <div className="float-left">
      <div>
        <a
          rel="noopener noreferrer"
          href="https://rebabre.com/app/mc-save/"
          target="_blank"
        >

    Mc Auto Save
        </a>
      </div>
      <br />
    </div>
  </div>
);

const Sm3 = ({ v3 }) => (
  <div id="sm-id-3" className={v3 ? 'headers-sm slideIn' : 'headers-sm slideOut'}>
    <a
      rel="noopener noreferrer"
      href="https://www.youtube.com/watch?v=G_CAzXMdpy4&list=PLbaPBUmUOOPwEm_P-UIxdwsuBqzos5kNT"
      target="_blank"
    >
Divine Communication
    </a>
    <br />
    <a
      rel="noopener noreferrer"
      href="https://rebabre.com/gyan/2017/04/19/Gurmat/"
      target="_blank"
    >
Intro to Gurmat
    </a>
    <br />
    <br />
    <a
      rel="noopener noreferrer"
      href="https://rebabre.com/worldproblems.html"
      target="_blank"
    >
World Problems
    </a>


    <br />
    <br />

    <div className="float-left2">

    Gyan
      <a
        rel="noopener noreferrer"
        href="https://rebabre.com/gyan//2017/04/19/Environment/"
        target="_blank"
      >
        <br />
Enivironment
      </a>
      <br />
      <a
        rel="noopener noreferrer"
        href="https://rebabre.com/gyan/2017/04/19/Gurmat/"
        target="_blank"
      >

Gurmat
        {' '}
      </a>
      <br />
      <a
        rel="noopener noreferrer"
        href="https://rebabre.com/gyan//2017/04/20/People/"
        target="_blank"
      >

People
      </a>
      <br />
      <a rel="noopener noreferrer" href="https://rebabre.com/gyan/" target="_blank">Activism</a>
      <br />
    </div>

  </div>
);

const Sm4 = ({ v4 }) => (
  <div id="sm-id-4" className={v4 ? 'headers-sm slideIn' : 'headers-sm slideOut'}>
    <a
      rel="noopener noreferrer"
      href="https://rebabre.com/business"
      target="_blank"
    >

  Business
    </a>
    <br />
    <a rel="noopener noreferrer" href="https://rebabre.com/about.html">Jujhar Singh</a>
    <br />
  </div>
);

const Sm5 = ({ v5 }) => (
  <div id="sm-id-5" className={v5 ? 'headers-sm slideIn' : 'headers-sm slideOut'}>

    Computer Eye Medicine  +
    {' '}
    <a href="https://rebabre.com/gyan/2017/04/23/Health/">Others</a>
    <br />

    <a href="https://t.me/rebare">
      <span role="img" aria-label="speech bubble">💬</span>
      {' '}
t.me/rebare
    </a>
    <br />
  </div>
);

class Homepage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchVal: '',
      // sm1: false,
      visible: false,
      hide: 0,
      v2: false,
      v3: false,
      v4: false,
      v5: false,
    };

    this.searchInputChange = this.searchInputChange.bind(this);
    this.hide = this.hide.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.fire = this.fire.bind(this);
  }

  searchInputChange(event) {
    /* eslint-disable */
    console.log(`Search term changed: ${this.state.searchTerm}`);
    this.setState({ searchVal: event.target.value });
  }

  fire(loc) {
    /* eslint-disable  */ // Iframe logic
     var elem = document.getElementById("trouble");
     let ifrm = document.createElement('iframe');
     ifrm.setAttribute('src', loc+this.state.searchVal);

     if (loc.includes('bing')) {
       ifrm.setAttribute('src', loc+this.state.searchVal+"&qft=+filterui:license-L1");
     }

     ifrm.setAttribute('width',"80%");
     ifrm.setAttribute('height',"500px");
     elem.prepend(ifrm);
  }

  handleClick(num) {
    /* eslint-disable no-console */
    console.log(num);

    if (num === 1) {
      this.setState(prev => ({ visible: !prev.visible }));
    }

    if (num === 2) {
      this.setState(prev => ({ v2: !prev.v2 }));
    }

    if (num === 3) {
      this.setState(prev => ({ v3: !prev.v3 }));
    }

    if (num === 4) {
      this.setState(prev => ({ v4: !prev.v4 }));
    }

    if (num === 5) {
      this.setState(prev => ({ v5: !prev.v5 }));
    }
  }

  hide() {
    /* eslint-disable no-console */
    console.log('Hiding');
    this.setState({ hide: 1 });
  }

  render() {
    return (
      <div id="wrapper">
        <div id="nav">



          &nbsp;&nbsp;&nbsp;&nbsp;


        </div>

        <br />
        <img src="images/rebabre.png" width="550px" alt="rebabre flag" />
        <br />
        <input
          id="searchBar"
          type="text"
          onChange={this.searchInputChange}
          value={this.state.searchVal}
        />
        <br />

        {' '}
        <a rel="noopener noreferrer" target="_blank" onClick={() => this.fire('https://commons.wikimedia.org/w/index.php?title=Special:Search&redirs=0&search=')}><img className="icon" src="images/wi.png" alt="wikiped" /></a>
        <a rel="noopener noreferrer" target="_blank" onClick={() => this.fire('https://www.bing.com/images/search?q=')}><img className="icon" src="images/bi.png" alt="bing" /></a>—
        <a rel="noopener noreferrer" target="_blank" href={`https://www.youtube.com/results?search_query=${this.state.searchVal}`}>
          <img className="icon" src="images/yt.png" alt="yt" />
        </a>
        <a rel="noopener noreferrer" target="_blank" href={`https://vimeo.com/search?q=${this.state.searchVal}`}><img className="icon" src="images/vi.png" alt="vo" /></a>
        <a rel="noopener noreferrer" target="_blank" href={`https://www.behance.net/search?content=projects&sort=appreciations&time=week&featured_on_behance=true&search=${this.state.searchVal}`}>
          <img className="icon" src="images/be.png" alt="be" />
        </a>
        <a rel="noopener noreferrer" target="_blank" href={`https://twitter.com/search?q=${this.state.searchVal}&src=typd`}><img className="icon" src="images/tw.png" alt="tw" /></a>
        {' '}
        <a rel="noopener noreferrer" target="_blank" style={{verticalAlign: 'super' }}
           href={`https://www.google.com/search?q=${this.state.searchVal}%20site:jujharpannu.com%20OR%20site:rebabre.com`} title="jujhar">ਜੁਝਾਰ</a>
        <br />
        <br />
        <br />
        <div id="trouble">11:11</div>
        <div id="components" style={{ visibility: 'hidden' }}>
          <h3
            id="h-id-2"
            className="headers"
            onClick={() => this.handleClick(2)}
            onKeyPress={() => this.handleClick(2)}
          >
Projects
          </h3>
          <h3
            id="h-id-3"
            className="headers"
            onClick={() => this.handleClick(3)}
            onKeyPress={() => this.handleClick(3)}
          >
Rouse
          </h3>
          <h3
            id="h-id-1"
            className="headers"
            onClick={() => this.handleClick(1)}
            onKeyPress={() => this.handleClick(1)}
          >

Art
          </h3>
          <h3
            id="h-id-4"
            className="headers"
            onClick={() => this.handleClick(4)}
            onKeyPress={() => this.handleClick(4)}
          >
About
          </h3>
          <h3
            id="h-id-5"
            className="headers"
            onClick={() => this.handleClick(5)}
            onKeyPress={() => this.handleClick(5)}
          >
          Health & Help
          </h3>
          <br />
          <br />

          <div className="headers-sm">
            <Sm1 id="sm-id-1" visible={this.state.visible} />
          </div>
          <div id="bottom-wrapper">
            <Sm2 v2={this.state.v2} />

            <Sm3 v3={this.state.v3} />

            <Sm4 v4={this.state.v4} />

            <Sm5 v5={this.state.v5} />
          </div>
        </div>

        <div id={this.state.hide ? 'no-optimized-hidden' : 'no-optimized'}>

          Not made for mobile devices
          <br />

          We recommend you view on a desktop, laptop, or TV
          <br />

          ਨਕੀ ਸਕਰੀਨ ਬੋਹਤ ਛੋਟੀ ਹੈ
          <br />
          <br />
          <a
            onClick={this.hide}
            onKeyPress={this.hide}
            role="button"
            tabIndex={0}
          >
Proceed anyway
          </a>
        </div>
      </div>
    );
  }
}

Sm1.propTypes = {
  visible: PropTypes.bool,
};

Sm1.defaultProps = {
  visible: false,
};

Sm2.propTypes = {
  v2: PropTypes.bool,
};

Sm2.defaultProps = {
  v2: false,
};

Sm3.propTypes = {
  v3: PropTypes.bool,
};

Sm3.defaultProps = {
  v3: false,
};

Sm4.propTypes = {
  v4: PropTypes.bool,
};

Sm4.defaultProps = {
  v4: false,
};

Sm5.propTypes = {
  v5: PropTypes.bool,
};

Sm5.defaultProps = {
  v5: false,
};

ReactDOM.render(<Homepage />, document.getElementById('react'));
